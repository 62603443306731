@font-face {
	font-family: 'Iosevka Aile Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('woff2/iosevka-aile-regular.woff2') format('woff2'), url('woff/iosevka-aile-regular.woff') format('woff'), url('ttf/iosevka-aile-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Iosevka Aile Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	src: url('woff2/iosevka-aile-bold.woff2') format('woff2'), url('woff/iosevka-aile-bold.woff') format('woff'), url('ttf/iosevka-aile-bold.ttf') format('truetype');
}
